import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Part } from '../interface/part';
import { first, switchMap } from 'rxjs/operators';
import { PartUtilities } from '../class/part-utilities';
import { ApiPaginatedQueryParams, ApiPaginatedResponse, PaginationService } from './pagination.service';
import { Observable, firstValueFrom } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class PartService {
  private _isFromMatrix = true;
  parts: Part[] = [];

  constructor(
    private http: HttpClient,
    private _dataService: DataService
  ) { }

  async get(id: string): Promise<Part> {
    const url = `${environment.mkgoURL}/api/v1/parts/${id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http
      .get(url, header)
      .pipe(first())
      .toPromise();
    if (!response.parts.length) {
      throw new Error(`Part with id: ${id} has not found.`);
    }
    const apiPart = response.parts[0];
    const part = PartUtilities.complyApp(apiPart);
    return part;
  }

  async getAll(): Promise<Part[]> {
    const url = `${environment.mkgoURL}/api/v1/parts`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http
      .get(url, header)
      .pipe(first())
      .toPromise();
    (response["parts"] as Part[]).map(PartUtilities.complyApp);
    this.parts = (response["parts"] as Part[]).reverse();
    return this.parts;
  }

  getPaginated(apiParams: ApiPaginatedQueryParams): Observable<ApiPaginatedResponse<Part>> {
    const url = `${environment.mkgoURL}/api/v1/parts/pages`;
    const params = PaginationService.getParams(apiParams);
    return this._dataService.httpOptions(this._isFromMatrix, params).pipe(
      switchMap(options => this.http.get<ApiPaginatedResponse<Part>>(url, options)),
      first()
    );
  }

  async addOrUpdate(part: Part): Promise<string> {
    const apipart = PartUtilities.complyAPI(part);
    const url = `${environment.mkgoURL}/api/v1/parts`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: { id: string } = (await this.http
      .post(url, apipart, header)
      .pipe(first())
      .toPromise()) as any;
      return resp.id;
  }

  async addOrUpdateMany(parts: Part[]) {
    let part;
    let promises: Promise<any>[] = [];
    for (let i = 0; i < parts.length; i++) {
      part = parts[i];
      promises.push(this.addOrUpdate(part));
    }
    await Promise.all(promises);
  }

  /*async delete(id: string): Promise<Part> {
    const url = `${environment.mkgoURL}/api/v1/parts/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http
      .delete(url, header)
      .pipe(first())
      .toPromise();
    return resp;
  }*/
  async delete(id: string): Promise<Part> {
    const url = `${environment.mkgoURL}/api/v1/parts/${id}/disable`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http
      .put(url, {}, header)
      .pipe(first())
      .toPromise();
    return resp;
  }

  async checkUse(id: string) {
    const url = `${environment.mkgoURL}/api/v1/parts/${id}/check/in/use`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http
      .get(url, header)
      .pipe(first())
      .toPromise();
    return response;
  }
}